import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import './tipptap.css';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import Navigation from './components/navigation';
import * as Pages from './pages';

import * as ROUTES from './constants/routes';
import { withAuthentication, AuthAdminContext } from './services/firebase/session';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

class App extends React.Component {
  render() {
    return (
      <Elements stripe={stripePromise}>
        <Router>
          <div className="container-fluid h-100">
            <div className="row h-100">
              <div className="col-md-2 col-sm-3 m-0 p-0 h-100 turquoise-bg">
                <Navigation />
              </div>
              <div className="col">
                <div className="container">
                  <div className="row">
                    <div className="col-12 py-5">
                      <AuthAdminContext.Consumer>
                        {auth => auth.admin ? <AuthContent /> : <NonAuthContent loading={auth.loading} />}
                      </AuthAdminContext.Consumer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Router>
      </Elements>
    );
  }
}

const AuthContent = () => (
  <Switch>
    <Route exact path={ROUTES.DASHBOARD} component={Pages.Dashboard} />
    <Route path={ROUTES.TRANSACTIONS} component={Pages.Transactions} />
    <Route path={ROUTES.EDIT_USER} component={Pages.EditUser} />
    <Route path={ROUTES.USERS} component={Pages.Users} />
    <Route path={ROUTES.REFERRALS} component={Pages.Referrals} />
    <Route path={ROUTES.ADMINS} component={Pages.Admins} />
    <Route path={ROUTES.SIGN_IN} component={Pages.SignIn} />
  </Switch>
);

const NonAuthContent = (props) => (
  props.loading
    ? <LoadingContent />
    : <Switch>
      <Route path={ROUTES.FORGOT_PASSWORD} component={Pages.ForgotPassword} />
      <Route path="/" component={Pages.SignIn} />
    </Switch>
)

const LoadingContent = () => (<h4>loading...</h4>)

export default withAuthentication(App);