import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";


const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID
};


class Firebase {
  constructor() {
    app.initializeApp(config);
    if (process.env.NODE_ENV === 'development') {
      console.log("💻 RUNNING IN DEVELOPMENT")
      app.functions().useEmulator("localhost", 5001);
      // app.database().useEmulator('localhost', 9000);
    }

    this.auth = app.auth();
    this.db = app.database();
    this.functions = app.functions();

    this.addUser = this.functions.httpsCallable("createUser");
    this.findUserByPhoneNumber = this.functions.httpsCallable("findUserByPhoneNumber");
    this.exportAllUsers = this.functions.httpsCallable("exportAllUsers");
    this.exportAllTransactions = this.functions.httpsCallable("exportAllTransactions");
    this.createAdmin = this.functions.httpsCallable("createAdmin");
    this.removeAdmin = this.functions.httpsCallable("removeAdmin");
    this.getSavedCards = this.functions.httpsCallable("getSavedCards");
    this.createSaveCardIntent = this.functions.httpsCallable("getSaveCardIntent");
    this.makePrimaryCard = this.functions.httpsCallable("makePrimaryCard");
    this.deleteCard = this.functions.httpsCallable("deleteCard");
    this.testPushNotification = this.functions.httpsCallable("testPushNotification");
  }

  /// ADMINS
  /* Auth */
  singInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);
  sendPasswordResetEmail = (email) => this.auth.sendPasswordResetEmail(email);
  signOut = () => this.auth.signOut();

  /* DB */
  adminsRef = () => this.db.ref('admins');
  
  /* Functions */
  
  /* Referrals */
  primaryCardRef = () => this.db.ref('metadata').child('primary_card_id');
  
  
  /// USERS
  /* DB */
  users = () => this.db.ref('users');
  userEvents = () => this.db.ref('user_events');
  usersCount = () => this.db.ref('metadata/total_users_count');
  
  /* Functions */
  
  /// TRANSACTIONS
  /* DB */
  transactions = () => this.db.ref('transactions');
  transactionsCount = () => this.db.ref('metadata/total_transactions_count');
}

export default Firebase;