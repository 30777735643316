import React from 'react';
import { withFirebase } from '../../services/firebase';


class Admins extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      serverActivity: false,
      serverMessage: null,
      serverMessageType: "info", // "info", "success", "danger"
      admins: [],
      adminEmail: null,
      adminPassword: null,
    };

    this.adminsRef = this.props.firebase.adminsRef();
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.adminsRef.on('value', snapshot => {
      let retrievedAdmins = [];
      snapshot.forEach(adminSnap => {
        retrievedAdmins.push(adminSnap.val())
      });
      this.setState({
        admins: retrievedAdmins,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.adminsRef.off();
  }

  handleChange = (event) => this.setState({ [event.target.name]: event.target.value })


  addAdmin = async (event) => {
    event.preventDefault();
    this.setState({ serverActivity: true, serverMessage: null })

    const createAdmin = this.props.firebase.createAdmin;
    try {
      const response = await createAdmin({
        adminEmail: this.state.adminEmail,
        adminPassword: this.state.adminPassword,
      });
      this.setState({ serverActivity: false, serverMessage: response.data.message, serverMessageType: "success", adminEmail: null })
    } catch (error) {
      this.setState({ serverActivity: false, serverMessage: error.message, serverMessageType: "danger" })
    }
  }


  deleteAdmin = async (adminEmail) => {
    this.setState({ serverActivity: true, serverMessage: null });

    const removeAdmin = this.props.firebase.removeAdmin;
    try {
      const response = await removeAdmin({ adminEmail: adminEmail })
      this.setState({ serverActivity: false, serverMessageType: "info", serverMessage: response.data.message });
    } catch (error) {
      this.setState({ serverActivity: false, serverMessageType: "danger", serverMessage: error.message })
    }
  }


  render() {
    const {
      admins,
      loading,
      serverActivity,
      serverMessage,
      serverMessageType,
    } = this.state;

    const adminEmail = this.state.adminEmail || '';
    const adminPassword = this.state.adminPassword || '';

    if (loading) { return <h3>Fetching data...</h3> }

    return (
      <div className="row">
        <div className="col col-sm-8 offset-sm-2 col-md-6 offset-sm-3">
          <form onSubmit={this.addAdmin} className="mb-4 pt-3">
            {serverMessage && <ErrorDisplay message={serverMessage} type={serverMessageType} />}
            <div className="input-group">
              <input type="email" name="adminEmail" className="form-control" placeholder="email" aria-label="new admin email" onChange={this.handleChange} value={adminEmail} />
              <input type="password" name="adminPassword" className="form-control" placeholder="password" aria-label="new admin password" onChange={this.handleChange} value={adminPassword} />
              <div className="input-group-append">
                <button type="submit" className="btn btn-outline-secondary" disabled={!adminEmail || serverActivity}>add admin</button>
              </div>
            </div>
          </form>
          <AdminsDisplay admins={admins} removeAdminHandler={this.deleteAdmin} />
        </div>
      </div>
    )
  }
}

const ErrorDisplay = (props) => (
  <div className={`alert alert-${props.type} my-3`}>{props.message}</div>
)

const AdminsDisplay = (props) => {
  const admins = props.admins

  const removeAdmin = (adminEmail, ev) => {
    if (window.confirm(`Are you sure you want to remove ${adminEmail}?`)) {
      props.removeAdminHandler(adminEmail)
    }
  }

  const adminList = admins.map(admin =>
    <li className="list-group-item d-flex justify-content-between" key={admin}>
      {admin}
      <button className="btn btn-sm btn-outline-danger descreet" onClick={(e) => removeAdmin(admin, e)}>remove</button>
    </li>
  )

  return (
    <ul className="list-group">
      {adminList}
    </ul>
  )
}

export default withFirebase(Admins);