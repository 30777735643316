import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

export default class PaginationNav extends React.Component {
    // This Component is not dislpayed if 'pageCount' is < 2

    render() {
        const pageCount = Math.ceil(this.props.itemsCount / this.props.pageSize);
        const currentPage = this.props.currentPage;

        let items = [
            <Pagination.Prev disabled={currentPage < 2} onClick={this.props.prevPageHandler} key={0} />,
            <Pagination.Item disabled key={currentPage}> {currentPage}</Pagination.Item>,
            <Pagination.Next disabled={currentPage >= pageCount} onClick={this.props.nextPageHandler} key={pageCount+1} />
        ];

        return (<Pagination className="float-right">{items}</Pagination>)
    }
}