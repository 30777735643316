import React from 'react';
import QRCode from 'react-qr-code';
import { withFirebase } from '../../../services/firebase';


class EditUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            bank_accounts: [],
            connected_account: null,
            currently_due_requirements: null,
            details_submitted: null,
            earnings: null,
            email: null,
            employer: null,
            error: null,
            events: [],
            external_accounts: null,
            first_name: null,
            last_name: null,
            id_status: null,
            onboarding_link: null,
            nationality: null,
            payouts_enabled: false,
            payouts_disabled_reason: null,
            eventually_due_requirements: null,
            past_due_requirements: null,
            registered_at: null,
            service_sector: null,
            suspensionReason: null,
            status: null,
            serverActivity: false,
            serverResponse: null,
        }

        this.uid = this.props.match.params.uid;
        this.userRef = this.props.firebase.users().child(this.uid);
        this.userEventsRef = this.props.firebase.userEvents().child(this.uid).orderByChild('timestamp').limitToLast(100);

        this.functions = this.props.firebase.functions;
    }


    componentDidMount() {
        this.userRef.on('value', snapshot => {
            const retrievedUserDetails = this.parseUserSnapshot(snapshot);
            this.setState({ ...retrievedUserDetails, loading: false });
        }, (error) => {
            if (error) { this.setState({ error: error.message, loading: false }); }
        });
        this.userEventsRef.on('value', snapshot => this.parseUserEventsFrom(snapshot))
    }


    componentWillUnmount() {
        this.userRef.off();
        this.userEventsRef.off();
    }


    parseUserEventsFrom = (snapshot) => {
        let receivedEvents = []
        snapshot.forEach(snap => {
            receivedEvents.push({
                author: snap.val().initiatedBy,
                message: snap.val().message,
                timestamp: snap.val().timestamp,
            });
        });
        this.setState({ events: receivedEvents.reverse() })
    }

    createConnectedAccount = async () => {
        this.setState({ serverResponse: null, serverActivity: true });

        const createStripeConnectedAccount = this.functions.httpsCallable('createStripeConnectedAccount');
        try {
            const response = await createStripeConnectedAccount({ userID: this.uid })
            this.setState({ serverActivity: false, serverResponse: response.data.message });
        } catch (error) {
            this.setState({ serverActivity: false, serverResponse: error.message })
        }
    }

    parseUserSnapshot(snapshot) { /* -> User */
        return {
            bank_accounts: snapshot.val().bank_accounts,
            connected_account: snapshot.val().connected_account,
            details_submitted: snapshot.val().details_submitted,
            dob: snapshot.val().dob,
            earnings: snapshot.val().earnings,
            email: snapshot.val().email,
            employer: snapshot.val().employer,
            first_name: snapshot.val().first_name,
            id_status: snapshot.val().id_status,
            last_name: snapshot.val().last_name,
            nationality: snapshot.val().nationality,
            payouts_enabled: snapshot.val().payouts_enabled,
            payouts_disabled_reason: snapshot.val().payouts_disabled_reason,
            currently_due_requirements: snapshot.val().currently_due_requirements,
            eventually_due_requirements: snapshot.val().eventually_due_requirements,
            past_due_requirements: snapshot.val().past_due_requirements,
            registered_at: snapshot.val().registered_at,
            service_sector: snapshot.val().service_sector,
            status: snapshot.val().status,
            uid: snapshot.key,
        }
    }

    handleChange = (event) => this.setState({ [event.target.name]: event.target.value })

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ serverResponse: null, serverActivity: true });

        this.userRef.update({
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            status: this.state.status,
            service_sector: this.state.service_sector,
            employer: this.state.employer,
        }, (error) => {
            if (error) {
                this.setState({ serverResponse: error.message, serverActivity: false });
            } else {
                this.setState({ serverResponse: "Saved changes", serverActivity: false })
            }
        });
    }

    suspendUser = async (event) => {
        event.preventDefault();
        this.setState({ serverResponse: null, serverActivity: true });
        const suspendUser = this.functions.httpsCallable('suspendUser');
        try {
            const response = await suspendUser({userID: this.uid, suspensionReason: this.state.suspensionReason });
            this.setState({ serverActivity: false, serverResponse: response.data?.message, suspensionReason: null });
        } catch (error) {
            this.setState({ serverActivity: false, serverResponse: error.message });
        }
    }

    activateUser = async () => {
        this.setState({ serverResponse: null, serverActivity: true });
        const activateUser = this.functions.httpsCallable('activateUser');
        try {
            const response = await activateUser({userID: this.uid });
            this.setState({ serverActivity: false, serverResponse: response.data?.message });
        } catch (error) {
            this.setState({ serverActivity: false, serverResponse: error.message });
        }
    }


    generateOnboardingLink = async () => {
        this.setState({ serverResponse: null, serverActivity: true });

        const generateOnboardingLink = this.functions.httpsCallable('generateOnboardingLinkForAdmin');
        const accountProps = {
            account: this.state.connected_account,
            refresh_url: window.location.href,
            return_url: window.location.href,
            type: 'account_onboarding',
        }

        try {
            const response = await generateOnboardingLink(accountProps);
            const onboardingLink = response.data.onboarding_link;
            this.setState({
                serverActivity: false,
                serverResponse: "Generated onboarding link. See below",
                onboarding_link: onboardingLink
            });
        } catch (error) {
            this.setState({ serverActivity: false, serverResponse: error.message })
        }
    }


    render() {
        const {
            connected_account,
            details_submitted,
            dob,
            earnings,
            email,
            employer,
            events,
            first_name,
            last_name,
            onboarding_link,
            nationality,
            payouts_enabled,
            payouts_disabled_reason,
            service_sector,
            serverActivity,
            suspensionReason,
            status,
            error,
        } = this.state

        const name = (first_name || 'n/a') + " " + (last_name || 'n/a');
        const tippingURL = "https://devpay.tipptap.com/" + this.uid;

        const registration_epoch = parseInt(this.state.registered_at);
        const registered_at = new Date(0);
        registered_at.setUTCSeconds(registration_epoch);

        if (this.state.loading) { return (<div><h3>Fetching data...</h3></div>) }

        if (this.state.error) { return (<div className="alert alert-danger">Read operation failed</div>) }

        return (
            <div>
                <div className="row">
                    <div className="col">
                        <h3>{name}</h3>
                    </div>
                    <div className="col">
                        {this.state.serverResponse && <div className="alert alert-info my-0">{this.state.serverResponse}</div>}
                    </div>
                </div>
                <hr className="mt-0" />
                <div className="row">
                    <div className="col">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="first_name" className="mb-0">first name</label>
                                <input type="text" onChange={this.handleChange} name="first_name" value={first_name} placeholder="First name" className="form-control mb-3" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="last_name" className="mb-0">last name</label>
                                <input type="text" onChange={this.handleChange} name="last_name" value={last_name} placeholder="Last name" className="form-control mb-3" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="first_name" className="mb-0">status</label>
                                <select className="form-control mb-3" name="status" value={status} onChange={this.handleChange} aria-label="User status select">
                                    <option value="active">active</option>
                                    <option value="suspended">suspended</option>
                                    <option value="new">new -- DEBUG ONLY</option>
                                    <option value="closed">closed -- DEBUG ONLY</option>
                                    <option value="inactive">inactive -- DEBUG ONLY</option>
                                    <option value="reactivation">reactivation -- DEBUG ONLY</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="service_sector" className="mb-0">service sector</label>
                                <input type="text" onChange={this.handleChange} name="service_sector" value={service_sector} placeholder="Service sector" className="form-control mb-3" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="employer" className="mb-0">employer</label>
                                <input type="text" onChange={this.handleChange} name="employer" value={employer} placeholder="Employer" className="form-control mb-3" />
                            </div>
                            <button type="submit" className="btn btn-primary btn-block mt-5" disabled={serverActivity}>update user</button>
                        </form>
                        {(status==='active') && (<form className="mt-5" onSubmit={this.suspendUser}>
                            <div className="input-group">
                                <input type="text" name="suspensionReason" className="form-control" placeholder="reason for suspension" aria-label="suspension reason" onChange={this.handleChange} value={suspensionReason} />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-outline-secondary" disabled={serverActivity}>suspend</button>
                                </div>
                            </div>
                            <small className="text-muted">User will be notified that the account got suspended. Suspended users can't receive tips.</small>
                        </form>)}
                        {(status!=='active') && <button className="btn btn-block btn-outline-secondary mt-5" onClick={this.activateUser} disabled={serverActivity}>activate user</button>}
                        <hr />
                        <h5>User history</h5>
                        <EventsDisplay events={events} />
                    </div>
                    <div className="col">
                        <h5>User details</h5>
                        <div className="text-center py-3 mb-3">
                            <QRCode value={tippingURL} size="128" fgColor="#1e3c5a" level="M" />
                        </div>
                        <ul className="list-group">
                            <li className="d-flex"><p className="mr-auto"><strong>UID</strong></p><p>{this.uid}</p></li>
                            <li className="d-flex"><p className="mr-auto"><strong>email</strong></p><p>{email ? email : '-'}</p></li>
                            <li className="d-flex"><p className="mr-auto"><strong>nationality</strong></p><p>{nationality}</p></li>
                            <li className="d-flex"><p className="mr-auto"><strong>date of birth</strong></p><p>{dob}</p></li>
                            <li className="d-flex"><p className="mr-auto"><strong>registered at</strong></p><p>{registered_at.toLocaleString()}</p></li>
                            <li className="d-flex"><p className="mr-auto"><strong>earnings</strong></p><p>{earnings ? `${earnings / 100.0} AED` : '-'}</p></li>
                        </ul>
                        <hr />
                        <h5>Stripe Connect</h5>
                        <ul className="list-group">
                            <li className="d-flex">
                                <p className="mb-2 mr-auto"><strong>Account</strong></p>
                                <p className="mb-2">{connected_account || 'none'}</p>
                            </li>
                            {!!connected_account && <li className="d-flex">
                                <p className="mb-2 mr-auto"><strong>Verified ID</strong></p>
                                <p className="mb-2">{details_submitted ? '✅' : '❌'}</p>
                            </li>}
                            {!!connected_account && <li className="d-flex">
                                <p className="mb-2 mr-auto"><strong>Payouts</strong></p>
                                <p className="mb-2">{payouts_enabled ? '✅' : '❌'}</p>
                            </li>}
                            {(!!connected_account && payouts_disabled_reason) && <li className="d-flex">
                                <p className="mb-2 mr-auto"><strong>Payouts disabled reason</strong></p>
                                <p className="mb-2">{payouts_disabled_reason}</p>
                            </li>}
                            {!connected_account &&
                                <li className="d-flex justify-content-end">
                                    <button className="btn btn-sm btn-primary mt-3" onClick={this.createConnectedAccount} disabled={serverActivity}>
                                        add connected account
                                    </button>
                                </li>
                            }
                            {connected_account && !(details_submitted && payouts_enabled) && !onboarding_link &&
                                <li className="d-flex justify-content-end">
                                    <button className="btn btn-sm btn-primary mb-3" onClick={this.generateOnboardingLink} disabled={serverActivity}>
                                        Generate onboarding link
                                    </button>
                                </li>
                            }
                            {connected_account && !(details_submitted && payouts_enabled) && onboarding_link &&
                                <li className="d-flex justify-content-between">
                                    <button className="btn btn-sm btn-outline-secondary mb-3" onClick={this.generateOnboardingLink} disabled={serverActivity}>
                                        Refresh link
                                    </button>
                                    <a href={onboarding_link} className="btn btn-sm btn-success mb-3" disabled={serverActivity}>launch onboarding</a>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

const EventsDisplay = (props) => {
    const events = props.events;
    const eventList = events.map((event) => <li key={event.timestamp}>{formatTimestamp(event.timestamp)} - {event.author} - {event.message}</li>)
    return (
        <ul className="list-unstyled">{eventList}</ul>
    )
}

/**
 *  Expects timestamp in seconds.
 *  Returns 
 */
function formatTimestamp(timestamp) {
    const dtFormat = new Intl.DateTimeFormat('en-GB', {
        dateStyle: 'short',
        timeStyle: 'short',
        timeZone: 'UTC'
    });

    return dtFormat.format(new Date(timestamp * 1000));
}

export default withFirebase(EditUser);