import React from 'react';
import { withFirebase } from '../../services/firebase';
import TransactionsTable from './transactionsTable';
import TransactionModal from './transactionModal';
import TransactionsControls from './transactionsControls';


class Transactions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeFilter: "all",
            currentPage: 1,
            error: null,
            loading: false,
            madeExportCall: false,
            totalTransactionsCount: null,
            selectedTransactionID: null,
            showTransactionModal: false,
            transactions: [],
        }

        this.pageSize = 12;
        this.totalTransactionsRef = props.firebase.transactionsCount();
        this.transactionsRef = props.firebase.transactions();
        this.defaultQuery = props.firebase.transactions().orderByChild('timestamp').limitToLast(1000);
        this.statusQuery = (status) => this.transactionsRef.orderByChild('status').equalTo(status).limitToLast(1000);
    }


    componentDidMount() {
        this.setState({ loading: true });

        this.totalTransactionsRef.once('value', snapshot => this.setState({ totalTransactionsCount: snapshot.val() }));

        this.defaultQuery.on('value', snapshot => {
            var retrievedTransactions = []
            snapshot.forEach(snap => { retrievedTransactions.push(this.parseTransactionSnapshot(snap)) });
            this.setState({ transactions: retrievedTransactions.reverse(), loading: false });
        }, (error) => {
            if (error) {
                this.setState({ error: error.message });
            }
        });
    }

    componentWillUnmount() {
        this.transactionsRef.off()
    }

    showTrxModal = (trx) => this.setState({ selectedTransactionID: trx.uid, showTransactionModal: true });
    hideTrxModal = () => this.setState({ selectedTransactionID: null, showTransactionModal: false });

    refundTransaction = (trx) => window.alert(`NOT IMPLEMENTED -- Should refund trx ID: ${trx.uid}`);
    approveTransaction = (trx) => window.alert(`NOT IMPLEMENTED -- Should approve trx ID: ${trx.uid}`);

    filterTransactions = (filter) => {
        this.setState({ currentPage: 1, activeFilter: filter });
        switch (filter) {
            case 'all':
                this.transactionsRef.off();
                this.defaultQuery.on('value', snapshot => {
                    var retrievedTransactions = []
                    snapshot.forEach(snap => { retrievedTransactions.push(this.parseTransactionSnapshot(snap)) });
                    this.setState({ transactions: retrievedTransactions.reverse(), loading: false });
                }, (error) => { !!error && this.setState({ error: error.message }); });
                break;
            case 'incomplete':
            case 'completed':
                this.transactionsRef.off();
                this.statusQuery(filter).on('value', snapshot => {
                    var retrievedTransactions = []
                    snapshot.forEach(snap => { retrievedTransactions.push(this.parseTransactionSnapshot(snap)) });
                    this.setState({ transactions: retrievedTransactions.reverse(), loading: false });
                }, (error) => { !!error && this.setState({ error: error.message }); });
                break;
        }
    }

    parseTransactionSnapshot = (snapshot) => {
        const transactionDict = snapshot.val();
        return {
            uid: snapshot.key,
            amount: transactionDict.amount,
            currency: transactionDict.currency,
            fee: transactionDict.fee,
            payment_processor_trx_id: transactionDict.payment_processor_trx_id,
            receiver_account: transactionDict.receiver_account,
            receiver_id: transactionDict.receiver_id,
            receiver_name: transactionDict.receiver_name,
            status: transactionDict.status,
            timestamp: transactionDict.timestamp,
            type: transactionDict.type,
        }
    }

    exportToCSV = () => {
        this.setState({ madeExportCall: true });
        this.props.firebase.exportAllTransactions();
    }


    /* Pagination handlers */
    browseNextPage = () => {
        console.log(`trx count: ${this.state.totalTransactionsCount} AND page size: ${this.pageSize}`);
        if (this.state.currentPage >= Math.ceil(this.state.totalTransactionsCount / this.pageSize)) { return }
        const nextPage = this.state.currentPage + 1;
        this.setState({ currentPage: nextPage });
    }

    browsePrevPage = () => {
        if (this.state.currentPage < 2) { return }
        const prevPage = this.state.currentPage - 1;
        this.setState({ currentPage: prevPage });
    }

    render() {
        const {
            activeFilter,
            currentPage,
            error,
            madeExportCall,
            selectedTransactionID,
            showTransactionModal,
            totalTransactionsCount,
            transactions,
        } = this.state

        const trx = transactions.find(trx => trx.uid === selectedTransactionID);
        const displayTransactionsStartIndex = (currentPage - 1) * 12;
        const displayTransactionsEndIndex = (currentPage * 12) - 1;
        const displayedTransactions = transactions.slice(displayTransactionsStartIndex, displayTransactionsEndIndex);

        if (!!error) { return (<div className="alert alert-danger">{error}</div>) }

        if (!!selectedTransactionID && trx === undefined) { return (<div className="alert alert-danger">Unexpected error.</div>); }

        return (
            <div>
                <TransactionsControls
                    activeFilter={activeFilter}
                    applyFilter={this.filterTransactions}
                    exportHandler={this.exportToCSV}
                    madeExportCall={madeExportCall} />
                <TransactionsTable
                    transactions={displayedTransactions}
                    onRowClick={this.showTrxModal}
                    pageSize={this.pageSize}
                    currentPage={currentPage}
                    totalTransactionsCount={totalTransactionsCount}
                    onPaginationPrev={this.browsePrevPage}
                    onPaginationNext={this.browseNextPage}
                />

                { !!trx &&
                    <TransactionModal
                        transaction={trx}
                        show={showTransactionModal}
                        onHide={this.hideTrxModal}
                        onRefund={this.refundTransaction}
                        onApprove={this.approveTransaction}
                    />
                }
            </div>
        )
    }
}


export default withFirebase(Transactions);