import React from 'react';
import { withFirebase } from '../../services/firebase';
import UsersControls from './usersControls';
import UsersTable from './usersTable';
import AddUserModal from './addUserModal';


class Users extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeFilter: "all",
            addUserError: null,
            currentPage: 1,
            error: null,
            loading: false,
            madeExportRequest: false,
            selectedUserId: null,
            serverActivity: false,
            showAddUserModal: false,
            totalUserCount: null,
            userLookupError: null,
            users: [],
        }

        this.pageSize = 12;
        this.totalUsersRef = props.firebase.usersCount();
        this.usersRef = props.firebase.users();
        this.defaultQuery = this.usersRef.orderByChild('registered_at').limitToLast(1000);
        this.statusQuery = (status) => this.usersRef.orderByChild('status').equalTo(status).limitToLast(1000);
    }


    componentDidMount() {
        this.setState({ loading: true });

        // Reads from /admins/ the count of total users.
        // We do this in order to not download the entire data tree for 'uses'
        // just to get a count.
        // Total users is (or should be) updated by a Firebase Function
        this.totalUsersRef.once('value', snapshot => this.setState({ totalUserCount: snapshot.val() }));

        this.defaultQuery.on('value', snapshot => {
            var retrievedUsers = []
            snapshot.forEach(snap => { retrievedUsers.push(this.parseUserSnapshot(snap)) });
            console.log("⚠️📣 :: default Query producing effects");
            this.setState({
                users: retrievedUsers.reverse(),
                loading: false,
            });
        }, (error) => {
            if (error) {
                this.setState({ error: error.message });
            }
        });
    }

    componentWillUnmount() {
        this.usersRef.off();
    }


    parseUserSnapshot = (snapshot) => { /* -> User */
        const userDict = snapshot.val();
        return {
            uid: snapshot.key,
            bank_accounts: userDict.bank_accounts,
            dob: userDict.dob,
            earnings: userDict.earnings,
            email: userDict.email,
            employer: userDict.employer,
            first_name: userDict.first_name,
            id_status: userDict.id_status,
            id_expiration: userDict.id_expiration,
            last_name: userDict.last_name,
            registered_at: userDict.registered_at,
            service_sector: userDict.service_sector,
            status: userDict.status,
        }
    }

    editUser = (user) => this.props.history.push(`/users/${user.uid}/edit`);
    addUser = async (firstName, lastName, nationality, phoneNumber) => {
        this.setState({ serverActivity: true, addUserError: null })
        const addUserFunction = this.props.firebase.addUser;
        try {
            const response = await addUserFunction({
                firstName: firstName,
                lastName: lastName,
                nationality: nationality,
                phoneNumber: phoneNumber,
            });
            this.setState({ serverActivity: false, addUserError: null });
            console.log(`Should redirect to edit: ${response.data.uid}`);
            this.hideAddUserModal();
            this.props.history.push(`/users/${response.data.uid}/edit`);
        } catch (error) { this.setState({ addUserError: error.message, serverActivity: false }); }
    }


    findUser = async (phoneNumber) => {
        this.setState({serverActivity: true, userLookupError: null});
        const findUser = this.props.firebase.findUserByPhoneNumber;
        findUser({ phoneNumber: phoneNumber })
            .then((response) => {
                const userId = response.data.uid;
                this.props.history.push(`/users/${userId}/edit`);
            })
            .catch((error) => { this.setState({ userLookupError: error.message }) });
        this.setState({ serverActivity: false });
    }


    /* Pagination handlers */
    browseNextPage = () => {
        if (this.state.currentPage >= Math.ceil(this.state.totalUserCount / this.pageSize)) { return }
        const nextPage = this.state.currentPage + 1;
        this.setState({ currentPage: nextPage });
    }

    browsePrevPage = () => {
        if (this.state.currentPage < 2) { return }
        const prevPage = this.state.currentPage - 1;
        this.setState({ currentPage: prevPage });
    }

    filterUsers = (filter) => {
        this.setState({ currentPage: 1, activeFilter: filter });

        switch (filter) {
            case 'all':
                this.usersRef.off();
                this.defaultQuery.on('value', snapshot => {
                    var retrievedUsers = []
                    snapshot.forEach(snap => { retrievedUsers.push(this.parseUserSnapshot(snap)) });
                    this.setState({
                        users: retrievedUsers.reverse(),
                        loading: false,
                    });
                }, (error) => {
                    if (error) {
                        this.setState({ error: error.message });
                    }
                });
                break;

            case 'new':                 // just registered
            case 'active':              // in good standing
            case 'inactive':            // don't payouts was disabled by Stripe Connect
            case 'suspended':           // suspended by admin
            case 'closed':              // requested account closure
            case 'reactivation':        // requested reactivation after closing their account
                this.usersRef.off();
                this.statusQuery(filter).on('value', snapshot => {
                    var retrievedUsers = []
                    snapshot.forEach(snap => { retrievedUsers.push(this.parseUserSnapshot(snap)) });
                    console.log("default Query producing effects");
                    this.setState({
                        users: retrievedUsers.reverse(),
                        loading: false,
                    });
                }, (error) => {
                    if (error) {
                        this.setState({ error: error.message });
                    }
                });
                break;
        }
    }

    exportToCSV = () => {
        this.setState({ madeExportRequest: true });
        this.props.firebase.exportAllUsers();
    }

    showAddUserModal = () => this.setState({ showAddUserModal: true });
    hideAddUserModal = () => this.setState({ showAddUserModal: false });


    render() {
        const {
            activeFilter,
            currentPage,
            error,
            madeExportRequest,
            selectedUserId,
            serverActivity,
            showAddUserModal,
            totalUserCount,
            userLookupError,
            users,
        } = this.state

        const user = users.find(user => user.uid === selectedUserId);
        const displayUsersStartIndex = (currentPage - 1) * 12;
        const displayUsersEndIndex = (currentPage * 12) - 1;
        const displayedUsers = users.slice(displayUsersStartIndex, displayUsersEndIndex);

        if (!!error) { return (<div className="alert alert-danger">{error}</div>) }

        if (!!selectedUserId && user === undefined) { return (<div className="alert alert-danger">Unexpected error.</div>); }

        return (
            <div>
                <UsersControls
                    activeFilter={activeFilter}
                    addUserHandler={this.showAddUserModal}
                    applyFilter={this.filterUsers}
                    exportCSVHandler={this.exportToCSV}
                    findUserWithPhoneNumber={this.findUser}
                    madeExportRequest={madeExportRequest}
                    serverActivity={serverActivity}
                    userLookupError={userLookupError}
                />
                <UsersTable
                    users={displayedUsers}
                    onRowClick={this.editUser}
                    pageSize={this.pageSize}
                    currentPage={currentPage}
                    totalUserCount={totalUserCount}
                    onPaginationPrev={this.browsePrevPage}
                    onPaginationNext={this.browseNextPage}
                />
                <AddUserModal
                    show={showAddUserModal}
                    onHide={this.hideAddUserModal}
                    addUser={this.addUser}
                    addUserError={this.state.addUserError}
                    serverActivity={serverActivity}
                />
            </div>
        )
    }
}

export default withFirebase(Users);