import React from 'react';
import { withFirebase } from '../../services/firebase';
import { CardSetupFormWithStripeElements } from './CardSetupForm';


class Referrals extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activity: true,
            customerId: null,
            primaryCardID: null,
            savedCards: [],
            setupIntentSecret: null, gettingSetupIntentSecret: false,
        };

        this.paymentSources = [];
    }


    async componentDidMount() {
        const primaryCardSnap = await this.props.firebase.primaryCardRef().once('value');
        const primaryCardID = primaryCardSnap.val();
        const getSavedCards = this.props.firebase.getSavedCards;
        const savedCardsResponse = await getSavedCards();

        this.setState({ activity: false, savedCards: savedCardsResponse.data.cards, primaryCardID: primaryCardID });
    }


    showAddCardElement = async () => {
        this.setState({ gettingSetupIntentSecret: true });
        const getSetupIntentSecret = this.props.firebase.createSaveCardIntent;
        const setupIntentResponse = await getSetupIntentSecret();
        const clientSecret = setupIntentResponse.data.client_secret;
        this.setState({ setupIntentSecret: clientSecret, gettingSetupIntentSecret: false })
        console.log("Create setup intent client secret: ", clientSecret);
    }


    handleSuccess = async () => {
        this.setState({ setupIntentSecret: null, gettingSetupIntentSecret: false });

        const getSavedCards = this.props.firebase.getSavedCards;
        const savedCardsResponse = await getSavedCards()
        this.setState({ savedCards: savedCardsResponse.data.cards });
    }

    makePrimaryCard = async (cardID) => {
        // expects 'primaryCardId'
        const makePrimary = this.props.firebase.makePrimaryCard;
        await makePrimary({
            primaryCardId: cardID,
        });
        const primaryCardSnap = await this.props.firebase.primaryCardRef().once('value');
        const primaryCardID = primaryCardSnap.val();
        this.setState({ primaryCardID: primaryCardID });
    }

    removeCard = async (cardID) => {
        // expects 'cardId'
        const removeCard = this.props.firebase.deleteCard;
        await removeCard({
            cardId: cardID,
        });
        const getSavedCards = this.props.firebase.getSavedCards;
        const savedCardsResponse = await getSavedCards();
        this.setState({ savedCards: savedCardsResponse.data.cards });
    }


    render() {
        if (this.state.activity) { return <h4>loading...</h4> }

        const availableCardIds = this.state.savedCards.map((card) => card.id);
        const referralCreditsEnabled = availableCardIds.includes(this.state.primaryCardID);

        // card cards. heh.
        const savedCards = this.state.savedCards;

        // expected Card Object format:
        // {
        //     id: string,
        //     last4: string,
        //     month: number?,
        //     year: number?,
        // }
        const cardCards = (savedCards === undefined || savedCards.length === 0) ? [] :
            savedCards.map((card) => <CardCard
                cardID={card.id}
                last4={card.last4}
                month={card.month}
                year={card.year}
                primary={card.id === this.state.primaryCardID}
                makePrimary={this.makePrimaryCard}
                remove={this.removeCard}
            />);


        return (
            <div className="row">
                <div className="col col-sm-8 offset-sm-2 col-md-6 offset-sm-3">
                    <h4>referral credits are: {referralCreditsEnabled ? "ON" : "OFF"}</h4>
                    <p>Crediting a referral needs a payment source (i.e. a card). To credit the referring user, the main card will be charged with 5AED + Stripe transfer fees.</p>
                    <strong className="mt-3">payment sources</strong>
                    {(cardCards.length === 0) ? <p>No saved payment sources</p> : cardCards}
                    <div>
                        <AddCardSection
                            gettingSetupIntentSecret={this.state.gettingSetupIntentSecret}
                            setupIntentSecret={this.state.setupIntentSecret}
                            showAddCardElement={this.showAddCardElement}
                            onSuccess={this.handleSuccess}
                        />
                    </div>
                    <div className="elements"></div>
                </div>
            </div>
        )
    }
}


const CardCard = (props) => {
    const baseClasses = "creditCard d-flex justify-content-between mb-3 p-3"
    const cardClasses = props.primary ? baseClasses + " primary" : baseClasses;

    const makePrimary = () => props.makePrimary(props.cardID);
    const remove = () => props.remove(props.cardID);

    return <div className={cardClasses} key={props.last4}>
        <div><p className="my-0 py-1">**** {props.last4} -- EXP: {props.month} / {props.year}</p></div>
        {(!props.primary) ? <div className="card-actions">
            <button className="btn btn-sm btn-outline-primary my-0 mr-3" onClick={makePrimary}>make primary</button>
            <button className="btn btn-sm btn-outline-danger my-0" onClick={remove}>remove</button>
        </div> : <></>}
    </div>;
}


const AddCardSection = (props) => {
    const { setupIntentSecret, gettingSetupIntentSecret } = props;

    const handleSuccess = () => {
        props.onSuccess();
    }

    if (gettingSetupIntentSecret) { return <button className="btn btn-outline-secondary btn-sm" disabled>loading...</button> }
    if (setupIntentSecret) {
        return <CardSetupFormWithStripeElements
            handleSuccess={handleSuccess}
            setupIntentSecret={setupIntentSecret} />
    }

    return <button className="btn btn-outline-secondary btn-sm" onClick={props.showAddCardElement}>Add Card</button>
}


export default withFirebase(Referrals);

