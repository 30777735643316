import React from 'react';
import PaginationNav from '../common/PaginationNav';


export default class TransactionsTable extends React.Component {
    // props: transactions, onRowClick
    constructor(props) {
        super(props);
    }

    handleRowClick = (transaction) => this.props.onRowClick(transaction);

    render() {
        const transactions = this.props.transactions;
        const itemsCount = this.props.totalTransactionsCount;
        const tableSize = this.props.pageSize;
        const currentPage = this.props.currentPage;
        const requiresPagination = itemsCount > tableSize;


        return (
            <div>
                <table className="table table-hover" >
                    <thead>
                        <tr>
                            <th scope="col" style={{ width: '10%' }}>ID</th>
                            <th scope="col" style={{ width: '10%' }}>Amount</th>
                            <th scope="col" style={{ width: '10%' }}>Fee</th>
                            <th scope="col" style={{ width: '15%' }}>Status</th>
                            <th scope="col" style={{ width: '10%' }}>Type</th>
                            <th scope="col" style={{ width: '25%' }}>Receiver</th>
                            <th scope="col" style={{ width: '20%' }}>Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map(trx => (
                            <TrxTableRow transaction={trx} onRowClick={this.handleRowClick} key={trx.uid} />
                        ))}
                    </tbody>
                </table>
                { requiresPagination &&
                    <PaginationNav
                        itemsCount={itemsCount}
                        pageSize={tableSize}
                        currentPage={currentPage}
                        prevPageHandler={this.props.onPaginationPrev}
                        nextPageHandler={this.props.onPaginationNext}
                    />}
            </div>
        )
    }
}

class TrxTableRow extends React.Component {
    constructor(props) {
        super(props);
    }

    handleClick = () => this.props.onRowClick(this.props.transaction);

    render() {
        const {
            uid,
            amount,
            currency,
            fee,
            status,
            type,
            receiver_name,
            timestamp,
        } = this.props.transaction;

        return (
            <tr key={uid} onClick={this.handleClick}>
                <td>{uid.slice(0,7)}</td>
                <td>{amount / 100.0} {currency.toUpperCase()}</td>
                <td>{fee / 100.0} {currency.toUpperCase()}</td>
                <td>{status}</td>
                <td>{type}</td>
                <td>{receiver_name}</td>
                <td>{formatTimestamp(timestamp)}</td>
            </tr>
        );
    }
}

/**
 *  Expects timestamp in seconds.
 *  Returns 
 */
function formatTimestamp(timestamp) {
    const dtFormat = new Intl.DateTimeFormat('en-GB', {
        dateStyle: 'short',
        timeStyle: 'medium',
        // timeZone: 'UTC'
    });

    return dtFormat.format(new Date(timestamp * 1000));
}