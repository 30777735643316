import React from 'react';
import PaginationNav from '../common/PaginationNav';


export default class UsersTable extends React.Component {
    // props: users, onRowClick
    constructor(props) {
        super(props);

        this.handleRowClick = this.handleRowClick.bind(this);
    }

    handleRowClick(user) {
        this.props.onRowClick(user);
    }


    render() {
        const users = this.props.users;
        const itemsCount = this.props.totalUserCount;
        const tableSize = this.props.pageSize;
        const currentPage = this.props.currentPage;
        const requiresPagination = itemsCount > tableSize;

        return (
            <div>
                <table className="table table-hover" >
                    <thead>
                        <tr>
                            <th scope="col" style={{ width: '10%' }}>ID</th>
                            <th scope="col" style={{ width: '30%' }}>Name</th>
                            <th scope="col" style={{ width: '30%' }}>Email</th>
                            <th scope="col" style={{ width: '20%' }}>Registration</th>
                            <th scope="col" style={{ width: '10%' }}>Earnings</th>
                            <th scope="col" style={{ width: '10%' }}>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <UserTableRow user={user} onRowClick={this.handleRowClick} key={user.uid} />
                        ))}
                    </tbody>
                </table>
                { requiresPagination &&
                    <PaginationNav
                        itemsCount={itemsCount}
                        pageSize={tableSize}
                        currentPage={currentPage}
                        prevPageHandler={this.props.onPaginationPrev}
                        nextPageHandler={this.props.onPaginationNext}
                    />}
            </div>
        )
    }
}



class UserTableRow extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        this.props.onRowClick(this.props.user);
    }

    render() {
        const {
            uid,
            earnings,
            email,
            first_name,
            last_name,
            registered_at,
            status,
        } = this.props.user;
        const name = (first_name || 'n/a') + " " + (last_name || 'n/a');
        const registrationDate = new Date(registered_at*1000); // registered_at should be in seconds
        const registration = Number.isInteger(registered_at) ? `${formatDate(registrationDate)}` : ''
        const userEarnings = Number.isInteger(earnings) ? `${earnings/100.0} AED` : '';


        return (
            <tr key={uid} onClick={this.handleClick} data-user={this.props.user}>
                <th scope="row">{uid.slice(0, 5)}</th>
                <td>{name}</td>
                <td>{email || 'n/a'}</td>
                <td>{registration}</td>
                <td className="text-right">{userEarnings}</td>
                <td>{status}</td>
            </tr>
        );
    }
}


function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}