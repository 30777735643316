import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default class UsersControls extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showFindUserForm: false,
        }
    }

    applyFilter = (filter) => this.props.applyFilter(filter);
    exportCSVHandler = () => this.props.exportCSVHandler();
    addUserHandler = () => this.props.addUserHandler();
    findUserWithPhoneNumber = (phoneNumber) => this.props.findUserWithPhoneNumber(phoneNumber);

    render() {
        const filters = ["all", "new", "active", "inactive", "suspended", "closed", "reactivation"];
        const filterButtons = filters.map(filter => (
            <button
                type="button"
                key={filter}
                onClick={this.applyFilter.bind(this, filter)}
                className={this.props.activeFilter === filter ? 'btn btn-secondary active' : 'btn btn-secondary'} >
                {filter}
            </button>
        ))

        return (
            <div className="row mb-4">
                <div className="col">
                    <div className="btn-group" role="group" aria-label="User filters">
                        {filterButtons}
                    </div>
                </div>
                <div className="col">
                    <FindUserByPhoneNumber
                        onSubmit={this.findUserWithPhoneNumber}
                        serverActivity={this.props.serverActivity}
                        userFound={this.props.userFound}
                        userLookupError={this.props.userLookupError}
                    />
                    <button className="btn btn-outline-secondary float-right" onClick={this.exportCSVHandler} disabled={this.props.madeExportRequest}>Export CSV</button>
                    <button className="btn btn-outline-secondary float-right mr-3" onClick={this.addUserHandler}>Add User</button>
                </div>
            </div>
        );
    }
}


class FindUserByPhoneNumber extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phoneNumber: null,
        }
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.props.onSubmit(this.state.phoneNumber);
    }

    handleChange = (event) => this.setState({ [event.target.name]: event.target.value })

    render() {
        const lookupError = this.props.userLookupError
        return <div>
            <form onSubmit={this.onSubmit}>
                <div className="input-group mb-3">
                    <input type="phone"
                        className={!!lookupError ? "form-control is-invalid" : "form-control"}
                        placeholder="+97155555555"
                        name="phoneNumber"
                        aria-label="Phone number"
                        aria-describedby="phone-lookup"
                        onChange={this.handleChange} />
                    <div className="input-group-append">
                        <button className="btn btn-outline-secondary" type="submit" disabled={this.props.serverActivity}><FontAwesomeIcon icon={faSearch}/></button>
                    </div>
                    {!!lookupError && <div className="invalid-feedback">{lookupError}</div>}
                </div>
            </form>
        </div>
    }
}