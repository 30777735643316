import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import CardSection from './CardSection';


class CardSetupForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activity: false,
            response: null,
            responseType: null,
        };
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({ activity: true, response: null, responseType: null });

        const { stripe, elements } = this.props

        if (!stripe || !elements) {
            console.error("🔥 no stripe or elements")
            return;
        }

        const result = await stripe.confirmCardSetup(this.props.setupIntentSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: 'TippTap Platform',
                },
            }
        });

        this.setState({ activity: false });

        if (result.error) {
            // result.error.message
            this.setState({ responseType: "error", response: result.error.message });
        } else {
            // result.setupIntent.payment_method
            this.setState({ responseType: "success", response: "Card added" });
            this.props.handleSuccess();
        }
    };


    render() {
        const messageClass = `alert alert-${this.state.responseType}`;

        return (
            <form onSubmit={this.handleSubmit}>
                <CardSection />
                <button className="btn btn-primary btn-sm" disabled={!this.props.stripe}>Save Card</button>
                {(this.state.response && this.state.responseType) && <p className={messageClass}>{this.state.response}</p>}
            </form>
        );
    }
}


export const CardSetupFormWithStripeElements = (props) => {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CardSetupForm {...props} stripe={stripe} elements={elements} />
            )}
        </ElementsConsumer>
    );
}