import React from 'react';
import Modal from 'react-bootstrap/Modal';

export default class AddUserModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: null,
            lastName: null,
            nationality: 'Emirati',
            phoneNumber: null,
        }
    }

    addUser = () => this.props.addUserHandler(this.user);
    handleChange = (event) => this.setState({ [event.target.name]: event.target.value })
    handleSubmit = (ev) => {
        ev.preventDefault();
        const { firstName, lastName, nationality, phoneNumber } = this.state;
        if (!!phoneNumber && !!firstName && !!lastName) {
            this.props.addUser(firstName, lastName, nationality, phoneNumber);
        } else {
            window.alert("All fields are mandatory");
        }
    }

    render() {
        const haveAllValues = !!this.state.firstName && !!this.state.lastName && !!this.state.phoneNumber;
        const error = this.props.addUserError;
        const serverActivity = this.props.serverActivity;

        return (
            <Modal show={this.props.show} onHide={this.props.onHide} >
                <form onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Register new user</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="firstName" className="mb-0"><small>first name</small></label>
                                    <input type="text" className="form-control" name="firstName" placeholder="Raj" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="lastName" className="mb-0"><small>last name</small></label>
                                    <input type="text" className="form-control" name="lastName" placeholder="Pichai" onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="nationality" className="mb-0"><small>nationality</small></label>
                                    <select className="form-control mb-3" name="nationality" value={this.state.nationality} onChange={this.handleChange} aria-label="user nationality">
                                        <option value="Emirati">Emirati</option>
                                        <option value="German">German</option>
                                        <option value="Romanian">Romanian</option>
                                        <option value="Indian">Indian</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="phoneNumber" className="mb-0"><small>phone number</small></label>
                            <input type="phone" className="form-control" name="phoneNumber" placeholder="+971123456789" onChange={this.handleChange} />
                        </div>
                        <div className="d-flex justify-content-end py-2">
                            {!!error && <div className="alert alert-danger py-1 mr-3 mb-0">{error}</div>}
                            {!!serverActivity && <div className="alert py-1 mr-3 mb-0 text-muted">processing...</div>}
                            <button type="submit" className="btn btn-primary" disabled={!haveAllValues || serverActivity}>Add User</button>
                        </div>
                    </Modal.Body>
                </form>
            </Modal>
        )
    }
}