import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/tt-logo.svg';

import SignOutButton from '../components/signOut';
import * as ROUTES from '../constants/routes';

import { AuthAdminContext } from '../services/firebase/session';

export default class Navigation extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <nav className="navbar">
                <div className="container-fluid my-3">
                    <div className="row">
                        <div className="col-12">
                            <img src={logo} alt="TippTap logo" className="fluid-image" id="logo" />
                        </div>
                        <div className="col-12">
                            <AuthAdminContext.Consumer>
                                {(auth) => (
                                    auth.admin
                                    ? <AuthNavigation /> 
                                    : <NonAuthNavigation loading={auth.loading} />
                                )}
                            </AuthAdminContext.Consumer>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

const NonAuthNavigation = () => (
    <ul className="list-unstyled p-0 nav flex-column mt-3">
    </ul>
);

const AuthNavigation = () => (
    <ul className="list-unstyled p-0 nav flex-column mt-3">
        <li className="p-0 py-1"><NavLink to={ROUTES.TRANSACTIONS} className="nav-link px-0" activeClassName="active">Transactions<span className="nav-highlight">.</span></NavLink></li>
        <li className="p-0 py-1"><NavLink to={ROUTES.USERS} className="nav-link px-0" activeClassName="active">Users<span className="nav-highlight">.</span></NavLink></li>
        <li className="p-0 py-1"><NavLink to={ROUTES.REFERRALS} className="nav-link px-0" activeClassName="active">Referrals<span className="nav-highlight">.</span></NavLink></li>
        <li className="p-0 py-1"><NavLink to={ROUTES.ADMINS} className="nav-link px-0" activeClassName="active">Admins<span className="nav-highlight">.</span></NavLink></li>
        <li className="p-0 py-1 mt-3"><SignOutButton /></li>
    </ul>
);