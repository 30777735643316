import React from 'react';
import { withFirebase } from './context';
import withAuthorization from './authorization';

const AuthAdminContext = React.createContext(null);

const withAuthentication = Component => {
    class WithAuthentication extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                admin: null,
                loading: true
            };
        }

        componentDidMount() {
            this.authStateChangedListener = this.props.firebase.auth.onAuthStateChanged(authUser => this.setState({ admin: authUser, loading: false }),
            );
        }

        componentWillUnmount() {
            this.authStateChangedListener();
        }

        render() {
            return (
                <AuthAdminContext.Provider value={this.state}>
                    <Component {...this.props} />
                </AuthAdminContext.Provider>
            )
        }
    }

    return withFirebase(WithAuthentication);
};

export { AuthAdminContext, withAuthentication, withAuthorization };
