import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import { withFirebase } from '../services/firebase';
import * as ROUTES from '../constants/routes';



export default class SignIn extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="col col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-center">
                    <h4 className="mb-3">login to continue</h4>
                    <LoginForm />
                </div>
            </div>
        );
    }
}

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null
}

class LoginFormBase extends React.Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = event => {
        event.preventDefault();
        this.props.firebase
            .singInWithEmailAndPassword(this.state.email, this.state.password)
            .then(user => {
                this.setState({ ...INITIAL_STATE })
                this.props.history.push(ROUTES.TRANSACTIONS);
            })
            .catch(error => {
                this.setState({ error });
            });
    }

    render() {
        const {
            email,
            password,
            error
        } = this.state;

        const isInvalid = password === '' || email === '';

        return (
            <form onSubmit={this.handleSubmit}>
                <div className="mb-3">
                    <input
                        placeholder="email"
                        className="form-control"
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                        type="email"
                    />
                </div>
                <div className="mb-3">
                    <input
                        placeholder="your secure password"
                        className="form-control"
                        name="password"
                        value={password}
                        onChange={this.handleChange}
                        type="password"
                    />
                </div>

                <button disabled={isInvalid} type="submit" className="btn btn-primary btn-block">Login</button>

                {error && <div className="alert alert-danger my-3">{error.message}</div>}

                <hr / >

                <Link to={ROUTES.FORGOT_PASSWORD} className="btn btn-link btn-sm">Forgot your password?</Link>
            </form>
        )
    }
}

const LoginForm = withRouter(withFirebase(LoginFormBase));