import React from 'react';
import { withRouter } from 'react-router-dom';

import { withFirebase } from '../services/firebase';
import * as ROUTES from '../constants/routes';



export default class ForgotPassword extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="col col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-center">
                    <h4 className="mb-3">reset password</h4>
                    <PasswordResetForm />
                </div>
            </div>
        );
    }
}

const INITIAL_STATE = {
    email: '',
    error: null
}

class ForgotPasswordFormBase extends React.Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit = event => {
        event.preventDefault();
        this.props.firebase
            .sendPasswordResetEmail(this.state.email)
            .then(() => {
                this.setState({ ...INITIAL_STATE })
                this.props.history.push(ROUTES.SIGN_IN);
            })
            .catch(error => {
                this.setState({ error });
            });
    }

    render() {
        const {
            email,
            error
        } = this.state;

        const isInvalid = email === '';

        return (
            <form onSubmit={this.handleSubmit}>
                <div className="mb-3">
                    <input
                        placeholder="Email"
                        className="form-control"
                        name="email"
                        value={email}
                        onChange={this.handleChange}
                        type="email"
                    />
                </div>

                <button disabled={isInvalid} type="submit" className="btn btn-primary btn-block">Request password reset</button>

                {error && <div className="alert alert-info my-3">{error.message}</div>}
            </form>
        )
    }
}

const PasswordResetForm = withRouter(withFirebase(ForgotPasswordFormBase));