import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default class TransactionModal extends React.Component {
    constructor(props) {
        super(props);
        this.transaction = this.props.transaction;
    }

    handleApprove = () => this.props.onApprove(this.transaction);
    handleRefund = () => this.props.onRefund(this.transaction);

    render() {
        const {
            uid,
            amount,
            currency,
            timestamp,
            status,
        } = this.props.transaction

        return(
            <Modal show={this.props.show} onHide={this.props.onHide} >
            <Modal.Header closeButton>
                <Modal.Title>Transaction details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <strong>amount</strong>: {amount / 100}<br />
                <strong>currency</strong>: {currency}<br />
                <strong>UID</strong>: {uid}<br />
                {/* <strong>timestamp:</strong> {(timestamp).toLocaleDateString()} */}
                <hr />
                <strong>Status</strong>: {status}<br />
            </Modal.Body>
            <Modal.Footer>
                {/* <Button variant="secondary" className="mr-auto" onClick={this.handleRefund} disabled>Refund</Button> */}
                <Button variant="secondary" onClick={this.props.onHide}>Close</Button>
                {status === 'pending' && <Button variant="primary" className="float-right" onClick={this.handleApprove}>Approve</Button>}
            </Modal.Footer>
        </Modal>
        )
    }
}