import React from 'react';

export default class TransactionsControls extends React.Component {
    constructor(props) {
        super(props);
    }

    applyFilter = (filter) => this.props.applyFilter(filter);
    exportHandler = () => this.props.exportHandler();


    render() {
        const filters = ["all", "incomplete", "completed"];
        const filterButtons = filters.map(filter => (
            <button
                type="button"
                key={filter}
                onClick={this.applyFilter.bind(this, filter)}
                className={this.props.activeFilter === filter ? 'btn btn-secondary active' : 'btn btn-secondary'}
                >
                {filter}
            </button>
        ))

        return (
            <div className="row mb-4">
                <div className="col">
                    <div className="btn-group" role="group" aria-label="Transaction filters">
                        {filterButtons}
                    </div>
                </div>
                <div className="col">
                    <button className="btn btn-outline-secondary float-right" onClick={this.exportHandler} disabled={this.props.madeExportCall}>Export CSV</button>
                </div>
            </div>
        );
    }
}